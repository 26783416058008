import React from "react";
import { Redirect } from "react-router";

import { TopActionBar } from "../../ActionBars/TopActionBar";

export class CollectionDetailTopActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  redirect() {
    this.setState({
      redirect: "/",
    });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            collectionDay: this.props.collectionDay,
            days: this.props.days,
          }}
        />
      );
    }
    return (
      <TopActionBar
        headerText={this.props.headerText}
        labelButtonRight={null}
        onClickButtonRight={false}
        displayButtonRight={false}
        labelButtonLeft={this.props.labelButtonLeft}
        onClickButtonLeft={this.redirect.bind(this)}
        displayButtonLeft={this.props.displayButtonLeft}
      />
    );
  }
}
