import React from "react";

import { CollectionDetailListItemInput } from "./CollectionDetailListItemInput";
// import { CollectionDetailListItemCheck } from './CollectionDetailListItemCheck';

export class CollectionDetailListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  componentDidMount() {
    if (
      !this.props.header &&
      !this.props.item.collected &&
      !this.props.item.delivered
    ) {
      this.setCollectionItemDefault();
    }
  }

  setCollectionItemProperty(property, value) {
    const item = this.props.item;
    item[property] = value;
    if ((property === "collected" || property === "delivered") && value > 0) {
      item.completed = true;
    }
    this.props.updateCollectionItem(item);
  }

  setCollectionItemDefault() {
    this.setCollectionItemProperty("collected", "");
    this.setCollectionItemProperty("delivered", "");
  }

  render() {
    return (
      <div className="collection-waste-info">
        <div className="collection-waste-info-label">Name:</div>
        <div className="collection-waste-info-text">
          {this.props.item.name
            ? this.props.item.service + " - " + this.props.item.name
            : this.props.item.service}
        </div>
        <div className="collection-waste-info-label">Collected:</div>
        <div className="collection-waste-info-input cwi-collected">
          {this.props.item.service === "Collection" ||
          this.props.item.service === "Exchange" ? (
            <CollectionDetailListItemInput
              classes=""
              defaultValue={this.props.item.collected || ""}
              placeholder={"0"}
              disable={false}
              handleInputChange={this.setCollectionItemProperty.bind(this)}
              propertyName="collected"
            />
          ) : (
            <CollectionDetailListItemInput
              classes="input-disabled"
              defaultValue={""}
              disable={true}
              handleInputChange={this.setCollectionItemProperty.bind(this)}
              propertyName="collected"
            />
          )}
        </div>
        <div className="collection-waste-info-label">EWC Code:</div>
        <div className="collection-waste-info-text">
          {this.props.item.ewc_code ? this.props.item.ewc_code : "-"}
        </div>
        <div className="collection-waste-info-label">Delivered:</div>
        <div className="collection-waste-info-input cwi-delivered">
          {this.props.item.service !== "Collection" ? (
            <CollectionDetailListItemInput
              classes=""
              defaultValue={this.props.item.delivered || ""}
              placeholder={"0"}
              disable={false}
              handleInputChange={this.setCollectionItemProperty.bind(this)}
              propertyName="delivered"
            />
          ) : (
            <CollectionDetailListItemInput
              classes="input-disabled"
              defaultValue={""}
              disable={true}
              handleInputChange={this.setCollectionItemProperty.bind(this)}
              propertyName="delivered"
            />
          )}
        </div>
        <div className="collection-waste-info-label">Haz Code:</div>
        <div className="collection-waste-info-text">
          {this.props.item.haz_code ? this.props.item.haz_code : "-"}
        </div>
        <div className="collection-waste-info-label">Weight(kg):</div>
        <div className="collection-waste-info-input cwi-weight">
          {this.props.item.service === "Collection" ||
          this.props.item.service === "Delivery" ||
          this.props.item.service === "Exchange" ? (
            <CollectionDetailListItemInput
              classes=""
              defaultValue={
                this.props.item.weight && this.props.item.weight != "0.00"
                  ? this.props.item.weight
                  : ""
              }
              placeholder={"0.00"}
              disable={false}
              handleInputChange={this.setCollectionItemProperty.bind(this)}
              propertyName="weight"
            />
          ) : (
            <CollectionDetailListItemInput
              classes="input-disabled"
              defaultValue={""}
              disable={true}
              handleInputChange={this.setCollectionItemProperty.bind(this)}
              propertyName="weight"
            />
          )}
        </div>
        <div className="collection-waste-info-label">Notes:</div>
        <div className="collection-waste-info-text text-red">
          {this.props.item.driver_notes ? this.props.item.driver_notes : "-"}
        </div>
      </div>
    );
  }
}
