import React from "react";

import { Input } from "../../Inputs/Input";

export class CollectionDetailListItemInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  handleChange(event) {
    const value = event.target.value;
    this.props.handleInputChange(this.props.propertyName, value);
  }

  render() {
    return (
      <Input
        type="number"
        className={this.props.classes}
        value={this.props.defaultValue}
        placeholder={this.props.placeholder}
        disable={this.props.disable}
        handleChange={this.handleChange.bind(this)}
      />
    );
  }
}
