import React from "react";
import { Redirect } from "react-router";

import { TopActionBar } from "../../ActionBars/TopActionBar";
import { BottomActionBar } from "../../ActionBars/BottomActionBar";
import CompletedCollectionsIndexedDB from "../../../../CompletedCollectionsIndexedDB";

export class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: {},
      signature: "",
      canvas: false,
      context: false,
      pendown: false,
    };
    if (!localStorage.getItem("driver")) {
      props.history.push({ pathname: "/driver" });
    }
    this.resizeCanvas = this.resizeCanvas.bind(this);
  }

  componentDidMount() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      this.completedCollectionsIndex = CompletedCollectionsIndexedDB;
      this.completedCollectionsIndex.createCompletedCollectionsIndexDB({
        objectStoreName: "collections",
        version: 3,
        onsuccess: this.createCompletedCollectionsSuccess.bind(this),
      });
    }
    var canvas = this.refs.canvas;
    var context = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    if (this.props.width && this.props.height) {
      canvas.width = this.props.width;
      canvas.height = this.props.height;
    }
    context.lineWidth = 5;
    context.fillStyle = "#ddd";
    window.addEventListener("resize", this.resizeCanvas, true);
    document.body.addEventListener("touchmove", this.preventDefault, {
      passive: false,
    });
    this.setState({
      canvas: this.refs.canvas,
      context: context,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCanvas, true);
    document.body.removeEventListener("touchmove", this.preventDefault, {
      passive: true,
    });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  resizeCanvas(event) {
    event.preventDefault();
    if (this.state.canvas) {
      var canvas = this.state.canvas;
      var tdu = canvas.toDataURL("image/png");
      var context = canvas.getContext("2d");
      var signature = new Image();
      canvas.width = event.target.outerWidth;
      canvas.height = event.target.outerHeight;
      signature.onload = function () {
        context.drawImage(signature, 0, 0);
      };
      signature.src = tdu;
      this.setState({
        canvas: canvas,
        context: context,
      });
    }
  }

  createCompletedCollectionsSuccess(event) {
    this.completedCollectionsIndex.db = event.target.result;
    this.setCollection();
  }

  setCollection() {
    if (this.props.location.collection) {
      this.getCompletedCollection(this.props.location.collection.unique_id);
    } else {
      this.setState({
        redirect: "/",
      });
    }
  }

  getCompletedCollection(key) {
    this.completedCollectionsIndex.getCollectionInStore({
      key: key,
      onsuccess: this.getCompletedCollectionSuccess.bind(this),
    });
  }

  getCompletedCollectionSuccess(event) {
    const result = event.target.result;
    if (result) {
      this.setState({
        collection: result,
      });
    } else {
      this.setState({
        collection: this.props.location.collection,
      });
    }
  }

  handleMouseDown(event) {
    this.setState({ pendown: true });
  }

  handleMouseMove(event) {
    event.preventDefault();
    var canvas = this.state.canvas;
    var x = event.pageX - canvas.offsetLeft;
    var y = event.pageY - canvas.offsetTop;
    var context = this.state.context;
    if (this.state.pendown) {
      context.lineTo(x, y);
    } else {
      context.moveTo(x, y);
    }
    context.stroke();
    this.setState({ canvas: canvas, context: context });
  }

  handleStrokeEnd(event) {
    event.preventDefault();
    this.canvasStore();
    this.setState({ pendown: false });
  }

  canvasStore() {
    this.setState({ signature: this.state.canvas.toDataURL("image/png") });
  }

  handleTouchStart(event) {
    event.preventDefault();
    this.setState({ pendown: true });
    this.state.context.beginPath();
  }

  handleTouchMove(event) {
    event.preventDefault();
    var canvas = this.state.canvas;
    var context = this.state.context;
    var touch = event.touches[0] || event.changedTouches[0];
    var x = touch.pageX - canvas.offsetLeft;
    var y = touch.pageY - canvas.offsetTop;
    if (this.state.pendown) {
      context.lineTo(x, y);
    }
    context.stroke();
    this.setState({ context: context });
  }

  handleOnClickBack() {
    this.setState({
      redirect: "/detail/" + this.props.location.collection.unique_id,
      collection: this.props.location.collection,
    });
  }

  handleOnClickSave() {
    var collection = this.props.location.collection;
    collection.signature = encodeURIComponent(
      String(this.state.signature).split(",")[1]
    );
    this.setState({
      collection: collection,
      redirect: "/detail/" + this.props.location.collection.unique_id,
    });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            collection: this.props.location.collection,
            signatureRedirect: true,
          }}
        />
      );
    }
    return (
      <>
        <TopActionBar
          headerText={this.props.location.headerText}
          labelButtonRight={null}
          onClickButtonRight={false}
          displayButtonRight={false}
          labelButtonLeft={this.props.location.labelButtonLeft}
          onClickButtonLeft={this.handleOnClickBack.bind(this)}
          displayButtonLeft={this.props.location.displayButtonLeft}
        />
        <canvas
          ref="canvas"
          id="customer-sign-area-full"
          width={this.state.canvas.width}
          height={this.state.canvas.height}
          onMouseDown={this.handleMouseDown.bind(this)}
          onMouseMove={this.handleMouseMove.bind(this)}
          onMouseUp={this.handleStrokeEnd.bind(this)}
          onMouseLeave={this.handleStrokeEnd.bind(this)}
          onTouchStart={this.handleTouchStart.bind(this)}
          onTouchMove={this.handleTouchMove.bind(this)}
          onTouchEnd={this.handleStrokeEnd.bind(this)}
          onTouchCancel={this.handleStrokeEnd.bind(this)}
        ></canvas>
        <BottomActionBar
          labelButtonRight={this.props.location.labelButtonRight}
          onClickButtonRight={this.handleOnClickSave.bind(this)}
          displayButtonRight={this.props.location.displayButtonRight}
        />
      </>
    );
  }
}
