import React from "react";
import { Redirect } from "react-router";

import { BottomActionBar } from "../../ActionBars/BottomActionBar";
import { ButtonRight } from "../../Buttons/ButtonRight";
import { ButtonLeft } from "../../Buttons/ButtonLeft";

export class CollectionListBottomActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  redirect() {
    this.setState({
      redirect: "/disposal-hazard",
    });
  }

  redirectDriver() {
    this.setState({
      redirect: "/driver",
    });
  }

  redirectOrdering() {
    window.location.href = "https://cws-online.co.uk/collections/driver-order/" + this.props.orderingLink
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div id="bottom-nav" className="collection-list nav-section">
        <ButtonLeft
          label={this.props.labelButtonLeft}
          onClick={this.redirectDriver.bind(this)}
          displayButton={this.props.displayButtonLeft}
          hideButton={
            this.props.hideButtonLeft || this.props.typeButtonLeft
              ? this.props.hideButtonLeft
              : true
          }
          typeButton={this.props.typeButtonLeft}
        />
        <img src="/cws-logo.png" className="ml-15" alt="CWS Logo"></img>
        <div className="app-info">
          <h2>Fusion Driver App</h2>
          <p>
            &#169; 2024 Clinical Waste Solutions (CWS LTD) <br></br>Version
            1.1.0
          </p>
        </div>
        <button
          className="btn-default nav-btn-square mr-15"
          onClick={this.redirectOrdering.bind(this)}
          style={{
            backgroundColor: "#008c8c",
            width: "80px",
            minWidth: "80px",
            padding: "0px"
          }}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9841 4.99255C12.9841 4.44027 13.4318 3.99255 13.9841 3.99255C14.3415 3.99255 14.6551 4.18004 14.8319 4.46202L17.5195 7.14964C17.9101 7.54016 17.9101 8.17333 17.5195 8.56385C17.129 8.95438 16.4958 8.95438 16.1053 8.56385L14.9841 7.44263V14.9926C14.9841 15.5448 14.5364 15.9926 13.9841 15.9926C13.4318 15.9926 12.9841 15.5448 12.9841 14.9926V5.042C12.984 5.03288 12.984 5.02376 12.9841 5.01464V4.99255Z"
              fill="currentColor"
            />
            <path
              d="M11.0159 19.0074C11.0159 19.5597 10.5682 20.0074 10.0159 20.0074C9.6585 20.0074 9.3449 19.82 9.16807 19.538L6.48045 16.8504C6.08993 16.4598 6.08993 15.8267 6.48045 15.4361C6.87098 15.0456 7.50414 15.0456 7.89467 15.4361L9.01589 16.5574V9.00745C9.01589 8.45516 9.46361 8.00745 10.0159 8.00745C10.5682 8.00745 11.0159 8.45516 11.0159 9.00745V18.958C11.016 18.9671 11.016 18.9762 11.0159 18.9854V19.0074Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <ButtonRight
          label={this.props.labelButtonRight}
          onClick={this.redirect.bind(this)}
          displayButton={this.props.displayButtonRight}
        />
      </div>
    );
  }
}
