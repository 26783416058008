import React from "react";

import { CollectionDetailListItem } from "./CollectionDetailListItem";

export class CollectionDetailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  setCollectionItem(collectionItem) {
    var collectionItems = this.props.collectionItems;
    var collectionItemIndex = collectionItems.findIndex(
      (i) => i.unique_id === collectionItem.unique_id
    );
    if (collectionItemIndex >= 0) {
      collectionItems[collectionItemIndex] = collectionItem;
    } else {
      collectionItems.push(collectionItem);
    }
    this.props.updateCollectionItems(collectionItems);
  }

  render() {
    const collectionItems = this.state.collectionItems;
    return (
      <>
        {Object.keys(collectionItems).length > 0 &&
          collectionItems.map((item, key) => {
            return (
              <CollectionDetailListItem
                key={key}
                header={false}
                item={item}
                updateCollectionItem={this.setCollectionItem.bind(this)}
              />
            );
          })}
      </>
    );
  }
}
