import React from "react";

import { CollectionDisposalNonHazList } from "./CollectionDisposalNonHazList";
import { CollectionDisposalHazList } from "./CollectionDisposalHazList";

export class CollectionDisposalSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <>
        <h2 className="no-margin text-center">Summary of waste for disposal</h2>
        {this.props.disposalType === "non_hazardous" && (
          <CollectionDisposalNonHazList collections={this.props.collections} />
        )}
        {this.props.disposalType === "hazardous" && (
          <CollectionDisposalHazList collections={this.props.collections} />
        )}
      </>
    );
  }
}
