import React from "react";
import { Redirect } from "react-router";

import { CollectionDetailTopActionBar } from "./CollectionDetailTopActionBar";
import { CollectionDetailBottomActionBar } from "./CollectionDetailBottomActionBar";
import { CollectionDetailPageContent } from "./CollectionDetailPageContent";
import CompletedCollectionsIndexedDB from "../../../../CompletedCollectionsIndexedDB";
import APIService from "../../../../APIService";

export class CollectionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: {},
      is_loaded: false,
      updated_collection: {},
    };
    if (!localStorage.getItem("driver")) {
      props.history.push({ pathname: "/driver" });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (CompletedCollectionsIndexedDB !== undefined) {
      this.completedCollectionsIndex = CompletedCollectionsIndexedDB;
      this.completedCollectionsIndex.createCompletedCollectionsIndexDB({
        objectStoreName: "collections",
        version: 3,
        onsuccess: this.createCompletedCollectionsSuccess.bind(this),
      });
    }
  }

  createCompletedCollectionsSuccess(event) {
    this.completedCollectionsIndex.db = event.target.result;
    this.setCollection();
  }

  addCompletedCollection() {
    // Remove any additonal items that are not completed
    var updated_collection = this.state.updated_collection;
    if (
      updated_collection.additional_collection_items !== undefined &&
      updated_collection.additional_collection_items.length > 0
    ) {
      var additional_items = [];
      updated_collection.additional_collection_items.forEach(
        (item, index, object) => {
          if (Object.keys(item.stock_item).length > 0 && item.completed === true && item.confirmed !== true) {
            item.confirmed = true;
            additional_items.push(item);
          } else if (item.confirmed === true) {
            additional_items.push(item);
          }
        }
      );
      updated_collection.additional_collection_items = additional_items;
    }
    APIService.postCollection(updated_collection.unique_id, updated_collection);
    this.completedCollectionsIndex.updateCollectionInStore({
      data: updated_collection,
      onsuccess: this.addCompletedCollectionSuccess.bind(this),
    });
  }

  addCompletedCollectionSuccess(event) {
    this.setState({ redirect: "/" });
  }

  getCompletedCollection(key) {
    this.completedCollectionsIndex.getCollectionInStore({
      key: key,
      onsuccess: this.getCompletedCollectionSuccess.bind(this),
    });
  }

  getCompletedCollectionSuccess(event) {
    const result = event.target.result;
    if (result && this.props.location.signatureRedirect === undefined) {
      this.setState({
        collection: result,
        is_loaded: true,
        updated_collection: result,
      });
    } else {
      this.setState({
        collection: this.props.location.collection,
        is_loaded: true,
        updated_collection: this.props.location.collection,
      });
    }
  }

  setCollection() {
    if (this.props.location.collection) {
      this.getCompletedCollection(this.props.location.collection.unique_id);
    } else {
      this.setState({
        redirect: "/",
      });
    }
  }

  setUpdatedCollection(collection) {
    this.setState({ updated_collection: collection });
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            collectionDay: this.props.location.collectionDay,
          }}
        />
      );
    } else {
      const collection = this.state.updated_collection;
      let collection_text = "";
      if (this.state.is_loaded === true) {
        let collection_date = new Date(collection.date);
        let day = collection_date.getDate();
        let month = collection_date.toLocaleString("default", {
          month: "short",
        });
        let year = collection_date.getFullYear();
        collection_text = `Collection ${day} ${month} ${year}`;
      }
      return (
        <>
          <CollectionDetailTopActionBar
            headerText={collection_text}
            labelButtonLeft=""
            displayButtonLeft={true}
            collectionDay={this.props.location.collectionDay}
            days={this.props.location.days}
          />
          <div id="collection-details" className="page-content">
            {this.state.is_loaded === true && (
              <CollectionDetailPageContent
                collection={collection}
                updateCollection={this.setUpdatedCollection.bind(this)}
                headerText={collection_text}
              />
            )}
          </div>
          <CollectionDetailBottomActionBar
            labelButtonRight="Confirm"
            displayButtonRight={true}
            onClickButtonRight={this.addCompletedCollection.bind(this)}
          />
        </>
      );
    }
  }
}
