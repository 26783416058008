import React from "react";

export class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    if (this.props.optionListMapping) {
      const value = this.props.optionListMapping.value;
      const text = this.props.optionListMapping.text;
      return (
        <>
          <select
            className={this.props.className}
            value={this.props.defaultValue}
            disabled={this.props.disable}
            onChange={(event) => this.props.handleChange(event)}
          >
            <option value="" readOnly hidden>Select...</option>
            {this.props.optionList.length > 0 &&
              this.props.optionList.map((val, index) => {
                return (
                  <option key={index} value={val[value]}>
                    {val[text]}
                  </option>
                );
              })}
          </select>
        </>
      );
    }
    return (
      <>
        <select
          className={this.props.className}
          value={this.props.defaultValue}
          disabled={this.props.disable}
          onChange={(event) => this.props.handleChange(event)}
        >
          <option value="" readOnly hidden>Select...</option>
          {this.props.optionList.length > 0 &&
            this.props.optionList.map((val, index) => {
              return (
                <option key={index} value={index}>
                  {val.text}
                </option>
              );
            })}
        </select>
      </>
    );
  }
}
