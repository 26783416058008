import React from "react";

export class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <input
        type="checkbox"
        name={this.props.name}
        className={this.props.className}
        defaultValue={this.props.value}
        disabled={this.props.disable}
        onChange={(event) => this.props.handleChange(event)}
        checked={this.props.defaultValue}
      />
    );
  }
}
