import React from "react";
import Randomstring from "randomstring";

import { CollectionDetailCustomer } from "./CollectionDetailCustomer";
import { CollectionDetailList } from "./CollectionDetailList";
import { CollectionDetailAddOnList } from "./CollectionDetailAddOnList";
import { CollectionDetailLegal } from "./CollectionDetailLegal";
import { CollectionDetailAddLineItem } from "./CollectionDetailAddLineItem";

import APIService from "../../../../APIService";

export class CollectionDetailPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      additional_collection_items: [],
    };
  }

  componentDidMount() {
    this.setStock();
    this.setAddOnCollectionItems();
  }

  setUpdatedCollectionItems(collectionItems) {
    var collection = this.props.collection;
    collection.collection_items = collectionItems;
    this.props.updateCollection(collection);
  }

  setUpdatedAdditionalCollectionItems(addtionalCollectionItems) {
    var collection = this.props.collection;
    collection.additional_collection_items = addtionalCollectionItems;
    this.props.updateCollection(collection);
  }

  setUpdatedCollection(collection) {
    this.props.updateCollection(collection);
  }

  setStock() {
    APIService.getStock().then((data) => this.setState({ stock: data.data }));
  }

  setAddOnCollectionItems(additional_collection_items = null) {
    if (additional_collection_items !== null) {
      this.setState({
        additional_collection_items: additional_collection_items,
      });
    } else if (this.props.collection.additional_collection_items) {
      this.setState({
        additional_collection_items:
          this.props.collection.additional_collection_items,
      });
    }
  }

  addProduct() {
    const addtionalCollectionItems = this.state.additional_collection_items;
    const unique_id = Randomstring.generate({
      length: "32",
      charset: "abcdef1234567890",
    });
    const additionalItem = {
      unique_id: unique_id,
      weight: "",
      completed: true,
      stock_item: {},
      confirmed: false
    };
    this.setState({
      additional_collection_items:
        addtionalCollectionItems.concat(additionalItem),
    });
  }

  render() {
    return (
      <>
        <CollectionDetailCustomer
          customer={this.props.collection.customer}
          driverNotes={this.props.collection.notes}
        />
        <CollectionDetailList
          collectionItems={this.props.collection.collection_items}
          updateCollectionItems={this.setUpdatedCollectionItems.bind(this)}
        />
        {this.state.additional_collection_items.length > 0 && (
          <CollectionDetailAddOnList
            addtionalCollectionItems={this.state.additional_collection_items}
            updateAdditionalCollectionItems={this.setUpdatedAdditionalCollectionItems.bind(
              this
            )}
            setAddOnCollectionItems={this.setAddOnCollectionItems.bind(this)}
            stock={this.state.stock}
          />
        )}
        <CollectionDetailAddLineItem onClick={this.addProduct.bind(this)} />
        <CollectionDetailLegal
          updateCollection={this.setUpdatedCollection.bind(this)}
          collection={this.props.collection}
          headerText={this.props.headerText}
        />
      </>
    );
  }
}
