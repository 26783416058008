import React from "react";

export class Textarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <textarea
        type={this.props.type}
        name={this.props.name}
        className={this.props.classes}
        onChange={(event) => this.props.handleChange(event)}
        defaultValue={this.props.defaultValue}
      ></textarea>
    );
  }
}
