import React from "react";

import { Checkbox } from "../../Inputs/Checkbox";

export class CollectionDetailListItemCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  handleChange(event) {
    const value = event.target.checked;
    this.props.handleInputChange(this.props.propertyName, value);
  }

  render() {
    return (
      <Checkbox
        className={this.props.classes}
        name={this.props.propertyName}
        value="1"
        disabled={false}
        handleChange={this.handleChange.bind(this)}
        defaultValue={this.props.defaultValue}
      />
    );
  }
}
