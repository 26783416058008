import React from "react";

export class CollectionDetailCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    let driver_notes_class = "driver-notes";
    let collection_address = [];
    if (!this.props.driverNotes || this.props.driverNotes === "")
      driver_notes_class += " no-display";
    if (this.props.customer.address.address_1)
      collection_address.push(this.props.customer.address.address_1);
    if (this.props.customer.address.town)
      collection_address.push(this.props.customer.address.town);
    if (this.props.customer.address.county)
      collection_address.push(this.props.customer.address.county);
    if (this.props.customer.address.postcode)
      collection_address.push(this.props.customer.address.postcode);
    return (
      <>
        <p className="text-center">
          <strong>{this.props.customer.name}</strong>
          <br></br>
          {collection_address.join(", ")}
        </p>
        <p className="text-center">
          {this.props.customer.phone ? this.props.customer.phone : ""}
        </p>
        <div className="div-half text-center margin-tb-ten">
          <p>
            <strong>Premises Code: </strong>
            {this.props.customer.premisis_code
              ? this.props.customer.premisis_code
              : "-"}
          </p>
        </div>
        <div className="div-half text-center margin-tb-ten">
          <p>
            <strong>SIC Process Code: </strong>
            {this.props.customer.sic_code ? this.props.customer.sic_code : "-"}
          </p>
        </div>
        <p className={driver_notes_class}>
          <i>
            <strong>Driver Notes: </strong>
            {this.props.driverNotes}
          </i>
        </p>
      </>
    );
  }
}
