import React from "react";
import { Redirect } from "react-router";

import { BottomActionBar } from "../../ActionBars/BottomActionBar";

export class DriverBottomActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  redirect() {
    this.setState({
      redirect: "/",
    });
  }

  redirectDriver() {
    this.setState({
      redirect: "/driver",
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <BottomActionBar
        labelButtonRight={this.props.labelButtonRight}
        onClickButtonRight={this.redirect.bind(this)}
        displayButtonRight={this.props.displayButtonRight}
        labelButtonLeft={this.props.labelButtonLeft}
        onClickButtonLeft={this.redirectDriver.bind(this)}
        displayButtonLeft={this.props.displayButtonLeft}
        hideButtonLeft={this.props.hideButtonLeft}
      />
    );
  }
}
