import React from "react";

import { BottomActionBar } from "../../ActionBars/BottomActionBar";

export class CollectionDisposalBottomActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <BottomActionBar
        labelButtonRight={this.props.labelButtonRight}
        onClickButtonRight={this.props.onClickButtonRight}
        displayButtonRight={this.props.displayButtonRight}
      />
    );
  }
}
