import React from "react";

import { CollectionDetailAddOnListItem } from "./CollectionDetailAddOnListItem";

export class CollectionDetailAddOnList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  setCollectionItem(collectionItem) {
    var addtionalCollectionItems = this.props.addtionalCollectionItems;
    var collectionItemIndex = addtionalCollectionItems.findIndex(
      (i) => i.unique_id === collectionItem.unique_id
    );
    if (collectionItemIndex >= 0) {
      addtionalCollectionItems[collectionItemIndex] = collectionItem;
    } else {
      addtionalCollectionItems.push(collectionItem);
    }
    this.props.updateAdditionalCollectionItems(addtionalCollectionItems);
  }

  removeCollectionItem(itemUID) {
    let addtionalCollectionItems = JSON.parse(JSON.stringify(this.props.addtionalCollectionItems));
    addtionalCollectionItems.forEach(item => console.log(item))
    let foundItemIndex = addtionalCollectionItems.findIndex((item) => item.unique_id === itemUID)
    if (foundItemIndex >= 0) {
      addtionalCollectionItems.splice(foundItemIndex, 1)
      this.props.setAddOnCollectionItems(addtionalCollectionItems)
    }
  }

  render() {
    const addtionalCollectionItems = this.props.addtionalCollectionItems;
    console.log(addtionalCollectionItems)
    return (
      <>
        {Object.keys(addtionalCollectionItems).length > 0 &&
          this.props.stock &&
          addtionalCollectionItems.map((item, key) => {
            return (
              <CollectionDetailAddOnListItem
                key={key}
                header={false}
                item={item}
                updateCollectionItem={this.setCollectionItem.bind(this)}
                removeCollectionItem={this.removeCollectionItem.bind(this)}
                stock={this.props.stock}
              />
            );
          })}
      </>
    );
  }
}
