import React from "react";

import { Sign } from "../../Inputs/Sign";
import { Input } from "../../Inputs/Input";

export class CollectionDisposalHazardLegal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    var signature = this.props.disposalCollection.signature;
    return (
      <div id="confirm-details-section">
        <div className="customer-sign">
          <div className="div-half">
            <h3>Carrier Certificate</h3>
            <p className="no-margin">
              Clinical Waste Solutions Ltd (CWS LTD), Unit 17, The Glenmore
              Centre, Fancy Road, Poole, BH12 4FB.<br></br>Registration Number
              CB/GE5809NN.
            </p>
            <h3>Cosignor certificate</h3>
            <p className="no-margin">
              I have received the accepted waste as detailed above at the above
              disposal address on the above date.<br></br>
              <br></br>I confirm that I have fulfilled my duty to apply the
              waste hierarchy as required by regulations 12 of the waste
              (England and Wales) Regulations 2011.
            </p>
            <div className="disposal-extra">
              <p>
                <strong>Permit Number</strong>
              </p>
              <Input
                type="text"
                className="input-full"
                value={this.props.disposalCollection.permit_number}
                disabled={false}
                handleChange={this.props.handlePermitChange.bind(this)}
              />
            </div>
          </div>
          <div className="div-half">
            <Sign
              collection={this.props.disposalCollection}
              headerText={this.props.headerText}
              imageUrl={
                signature !== undefined && signature !== "" ? signature : false
              }
              imageText="Cosignor Signature"
              imageLink={"/disposal-signature/374563078456b0v817635b"}
              imageHeight={350}
              imageWidth={350}
              label="Save"
            />
          </div>
        </div>
      </div>
    );
  }
}
