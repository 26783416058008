import React from "react";
import { ButtonRight } from "../Buttons/ButtonRight";
import { ButtonLeft } from "../Buttons/ButtonLeft";

export class BottomActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div id="bottom-nav" className="collection-list nav-section">
        <ButtonLeft
          label={this.props.labelButtonLeft}
          onClick={this.props.onClickButtonLeft}
          displayButton={this.props.displayButtonLeft}
          hideButton={
            this.props.hideButtonLeft || this.props.typeButtonLeft
              ? this.props.hideButtonLeft
              : true
          }
          typeButton={this.props.typeButtonLeft}
        />
        <img src="/cws-logo.png" className="ml-15" alt="CWS Logo"></img>
        <div className="app-info">
          <h2>Fusion Driver App</h2>
          <p>
            &#169; 2024 Clinical Waste Solutions (CWS LTD) <br></br>Version
            1.1.0
          </p>
        </div>
        <ButtonRight
          label={this.props.labelButtonRight}
          onClick={this.props.onClickButtonRight}
          displayButton={this.props.displayButtonRight}
        />
      </div>
    );
  }
}
