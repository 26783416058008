import React from "react";

import { CollectionDisposalHazardList } from "./CollectionDisposalHazardList";

export class CollectionDisposalHazardSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <>
        <h2 className="no-margin text-center">Summary of waste for disposal</h2>
        <CollectionDisposalHazardList collections={this.props.collections} />
      </>
    );
  }
}
