import React from "react";

class APIService extends React.Component {
  constructor(props) {
    super(props);
    this.baseAPIURL =
      props.baseAPIURL || `${process.env.REACT_APP_ADMIN_HOST}/api/driver/`;
  }

  getRequest({ path, method = "GET", headers = {} }) {
    const url = this.baseAPIURL + path;
    return fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error("Error:", error);
        throw new Error(`${error}`);
      });
  }

  postRequest({ path, method = "POST", headers = {}, data = {} }) {
    const url = this.baseAPIURL + path;
    return fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      credentials: "same-origin",
      body: data,
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error("Error:", error);
        throw new Error(`${error}`);
      });
  }

  async getCollections(uid) {
    return this.getRequest({
      path: "collections/" + uid,
      method: "GET",
      headers: {},
    });
  }

  async postCollection(uid, data) {
    return this.postRequest({
      path: "collection/" + uid,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: JSON.stringify(data),
    });
  }

  async postDisposal(data) {
    return this.postRequest({
      path: "disposal",
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: JSON.stringify(data),
    });
  }

  async getStock() {
    return this.getRequest({
      path: "stock",
      method: "GET",
      headers: {},
    });
  }

  async getDrivers() {
    return this.getRequest({
      path: "drivers",
      method: "GET",
      headers: {},
    });
  }
}

export default new APIService({
  baseAPIURL: `${process.env.REACT_APP_ADMIN_HOST}/api/driver/`,
});
