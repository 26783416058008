import React from "react";

export class CollectionDisposalHazardAddressDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <>
        {this.props.disposalType === "hazardous" && (
          <div className="text-center disposal-location">
            <h1 className="no-margin">SRCL Limited</h1>
            <p className="no-margin">
              Royal Bournemouth Hospital, Bournemouth, Dorset, BH7 7DW
            </p>
          </div>
        )}
      </>
    );
  }
}
