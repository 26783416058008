import React from "react";
import { ButtonLeft } from "../Buttons/ButtonLeft";
import { ButtonRight } from "../Buttons/ButtonRight";

export class TopActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  getDriver = () => {
    try {
      var driver = JSON.parse(localStorage.getItem("driver"));
      return driver.name;
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  render() {
    return (
      <div id="top-nav" className="collection-list nav-section">
        <ButtonLeft
          label={this.props.labelButtonLeft}
          onClick={this.props.onClickButtonLeft}
          displayButton={this.props.displayButtonLeft}
        />
        <div className="collection-date">
          <span>{this.props.headerText}</span>
          <span className="driver-name-label">{this.getDriver()}</span>
        </div>
        <ButtonRight
          label={this.props.labelButtonRight}
          onClick={this.props.onClickButtonRight}
          displayButton={this.props.displayButtonRight}
        />
      </div>
    );
  }
}
