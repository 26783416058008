import React from "react";

import { Textarea } from "../../Inputs/Textarea";
import { Checkbox } from "../../Inputs/Checkbox";

export class CollectionDetailComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  handleChange(event) {
    var value = event.target.value;
    var propertyName = "driver_comments";
    if (event.target.type === "checkbox") {
      value = event.target.checked;
      propertyName = "driver_no_access";
    }
    this.props.handleInputChange(propertyName, value);
  }

  render() {
    return (
      <>
        <h3>Driver comments</h3>
        <div className="driver-additional">
          <Textarea
            type="type"
            name="driver-comments"
            classes="driver-comments-area"
            handleChange={this.handleChange.bind(this)}
            defaultValue={
              this.props.collection.driver_comments !== undefined
                ? this.props.collection.driver_comments
                : ""
            }
          />
          <Checkbox
            className="driver-access-check"
            value="1"
            disabled={false}
            handleChange={this.handleChange.bind(this)}
            defaultValue={
              this.props.collection.driver_no_access !== undefined
                ? this.props.collection.driver_no_access
                : false
            }
          />
          <p>
            <strong>
              <i>
                Site was unaccessible/Wasted journey - no items were collected
              </i>
            </strong>
          </p>
        </div>
      </>
    );
  }
}
