import React from "react";

class IndexedDBService extends React.Component {
  createDB({ ...props }) {
    if (window.indexedDB) {
      this.request = window.indexedDB.open(props.dbName, props.version);
      this.request.onsuccess = props.onsuccess;
      this.request.onerror = props.onerror;
      this.request.onupgradeneeded = props.onupgradeneeded;
    }
  }

  getObjectStore(db, objectStoreName, mode) {
    var transaction = db.transaction(objectStoreName, mode);
    var objectStore = transaction.objectStore(objectStoreName);
    if (objectStore) {
      return objectStore;
    } else {
      return false;
    }
  }

  addData(objectStoreName, { data, onsuccess }) {
    if (this.db) {
      var objectStore = this.getObjectStore(
        this.db,
        objectStoreName,
        "readwrite"
      );
      if (objectStore !== null) {
        var request = objectStore.add(data);
        request.onsuccess = onsuccess;
        request.onerror = this.onTransactionError;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  deleteData(objectStoreName, { key, onsuccess }) {
    if (this.db) {
      var objectStore = this.getObjectStore(
        this.db,
        objectStoreName,
        "readwrite"
      );
      if (objectStore !== null) {
        var request = objectStore.delete(key);
        request.onsuccess = onsuccess;
        request.onerror = this.onTransactionError;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  clearData(objectStoreName, { onsuccess }) {
    if (this.db) {
      var objectStore = this.getObjectStore(
        this.db,
        objectStoreName,
        "readwrite"
      );
      if (objectStore !== null) {
        var request = objectStore.clear();
        request.onsuccess = onsuccess;
        request.onerror = this.onTransactionError;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  updateData(objectStoreName, { data, onsuccess }) {
    if (this.db) {
      var objectStore = this.getObjectStore(
        this.db,
        objectStoreName,
        "readwrite"
      );
      if (objectStore !== null) {
        var request = objectStore.put(data);
        request.onsuccess = onsuccess;
        request.onerror = this.onTransactionError;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getData(objectStoreName, { key = null, onsuccess }) {
    if (this.db) {
      var objectStore = this.getObjectStore(
        this.db,
        objectStoreName,
        "readonly"
      );
      if (objectStore !== null) {
        var request;
        if (key !== null) {
          request = objectStore.get(key);
        } else {
          request = objectStore.getAll();
        }
        request.onsuccess = onsuccess;
        request.onerror = this.onTransactionError;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onTransactionError(event) {
    console.log("[onerror]", event.target.error);
  }
}

export default IndexedDBService;
