import IndexedDBService from "./IndexedDBService";

class CompletedCollectionsIndexedDB extends IndexedDBService {
  constructor(props) {
    super(props);
    this.objectStore = null;
  }

  createCompletedCollectionsIndexDB({
    objectStoreName,
    version = 3,
    onsuccess,
  }) {
    this.nextObjectStore = objectStoreName;
    this.createDB({
      dbName: "CompletedCollections",
      version: version,
      onsuccess: (event) => onsuccess(event),
      onerror: (event) => this.onCompletedCollectionsDBError(event),
      onupgradeneeded: (event) =>
        this.onCompletedCollectionsDBUpgradeNeeded(event),
    });
  }

  onCompletedCollectionsDBError(event) {
    //console.log('[onerror]', event.target.error);
  }

  onCompletedCollectionsDBUpgradeNeeded(event) {
    this.db = event.target.result;
    //console.log('[onupgradeneeded]', this.db);
    if (this.nextObjectStore) {
      var objectStore = this.db.createObjectStore(this.nextObjectStore, {
        keyPath: "unique_id",
      });
      objectStore.createIndex("collection_unique_id", "unique_id", {
        unique: true,
      });
      this.objectStore = objectStore;
    }
  }

  onCompletedCollectionsDBSuccess(event) {
    this.db = event.target.result;
    //console.log('[onsuccess]', this.db);
  }

  getStore(mode) {
    if (this.nextObjectStore !== undefined) {
      var transaction = this.db.transaction(this.nextObjectStore, mode);
      var objectStore = transaction.objectStore(this.nextObjectStore);
      return objectStore;
    }
    return false;
  }

  addCollectionToStore({ data, onsuccess }) {
    this.addData("collections", { data, onsuccess });
    return true;
  }

  updateCollectionInStore({ data, onsuccess }) {
    this.updateData("collections", { data, onsuccess });
    return true;
  }

  getCollectionsInStore({ onsuccess }) {
    this.getData("collections", { onsuccess });
    return true;
  }

  getCollectionInStore({ key, onsuccess }) {
    this.getData("collections", { key, onsuccess });
  }

  deleteCollectionInStore({ key, onsuccess }) {
    this.deleteData("collections", { key, onsuccess });
    return true;
  }

  clearCollectionsInStore({ onsuccess }) {
    this.clearData("collections", { onsuccess });
    return true;
  }
}

export default new CompletedCollectionsIndexedDB();
