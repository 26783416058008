import React from "react";
import { Redirect } from "react-router";

import { TopActionBar } from "../../ActionBars/TopActionBar";

export class CollectionDisposalHazardTopActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  redirect() {
    this.setState({
      redirect: "/",
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const date = new Date();
    let day = date.getDate();
    let month = date.toLocaleDateString("default", { month: "short" });
    let year = date.getFullYear();
    let date_string = `Disposal ${day} ${month} ${year}`;
    return (
      <TopActionBar
        labelButtonLeft={this.props.labelButtonLeft}
        onClickButtonLeft={this.redirect.bind(this)}
        displayButtonLeft={this.props.displayButtonLeft}
        headerText={date_string}
      />
    );
  }
}
