import React, { useState } from "react";
import { Route } from "react-router-dom";
import "../../../../stylesheet.css";

export function Unauthenticated(props) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const login = () => {
    if (password === process.env.REACT_APP_AUTH_SECRET) {
      localStorage.setItem("auth", process.env.REACT_APP_AUTH_SECRET);
      props.history.push("/");
    } else {
      setError(true);
    }
  };

  return (
    <div className="login-main">
      <div className="login-container">
        <img className="login-img" src="/cws-logo.png" width="300" />
        <h2>CWS Driver App</h2>
        <div className="login-form">
          {error && <p style={{ color: "red" }}>Incorrect Password</p>}
          <input
            className="login-input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button className="btn-default login-button" onClick={login}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
}
