import React from "react";

export class ButtonRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    var btnClasses = "btn-default nav-btn-square mr-15";
    if (!this.props.displayButton) {
      btnClasses += " no-vis";
    }
    return (
      <button className={btnClasses} onClick={() => this.props.onClick()}>
        {this.props.label ? (
          <span className="btn-text btn-text-left">{this.props.label}</span>
        ) : (
          ""
        )}
        <span className="chevron chevron-right"></span>
      </button>
    );
  }
}
