import React from "react";

export class CollectionDisposalHazList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <>
        <div className="disposal-item-list">
          <div className="collection-waste-info collection-waste-info-header">
            <div className="dsp-col dsp-ewc-code">EWC Code</div>
            <div className="dsp-col dsp-haz-code">Hazard Code</div>
            <div className="dsp-col dsp-wgt">Estimated Weight Kg</div>
            <div className="dsp-col dsp-acc">Accept</div>
          </div>
          <div className="collection-waste-info">
            <div className="dsp-col dsp-ewc-code">180103</div>
            <div className="dsp-col dsp-haz-code">HP9</div>
            <div className="dsp-col dsp-wgt">10</div>
            <div className="dsp-col dsp-acc">
              <input type="checkbox" name=""></input>
            </div>
          </div>
          <div className="collection-waste-info">
            <div className="dsp-col dsp-ewc-code">180109</div>
            <div className="dsp-col dsp-haz-code">HP?</div>
            <div className="dsp-col dsp-wgt">106</div>
            <div className="dsp-col dsp-acc">
              <input type="checkbox" name=""></input>
            </div>
          </div>
        </div>
      </>
    );
  }
}
