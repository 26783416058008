import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  CollectionList,
  CollectionDetail,
  CollectionDisposal,
  CollectionDisposalHazard,
  Debug,
  Signature,
  DisposalSignature,
  Unauthenticated,
  Driver,
} from "./app/components/Pages/index";

const authenticate = (props) => {
  if (
    props.match.params.secret === process.env.REACT_APP_AUTH_SECRET ||
    localStorage.getItem("auth") === process.env.REACT_APP_AUTH_SECRET
  ) {
    // Set store
    localStorage.setItem("auth", process.env.REACT_APP_AUTH_SECRET);
    return true;
  } else {
    return false;
  }
};

export class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/driver"
            component={(props) =>
              authenticate(props) ? (
                <Driver {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/debug"
            component={(props) =>
              authenticate(props) ? (
                <Debug {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/detail/signature/:unique_id"
            component={(props) =>
              authenticate(props) ? (
                <Signature {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/detail/:unique_id"
            component={(props) =>
              authenticate(props) ? (
                <CollectionDetail {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/disposal"
            component={(props) =>
              authenticate(props) ? (
                <CollectionDisposal {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/disposal-hazard"
            component={(props) =>
              authenticate(props) ? (
                <CollectionDisposalHazard {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/disposal-signature/:unique_id"
            component={(props) =>
              authenticate(props) ? (
                <DisposalSignature {...props} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
          <Route
            path="/:secret?"
            component={(props) =>
              authenticate(props) ? (
                <CollectionList {...props} authenticated={true} />
              ) : (
                <Unauthenticated {...props} />
              )
            }
          />
        </Switch>
      </Router>
    );
  }
}
