import React from "react";

import { CollectionDisposalHazardListItem } from "./CollectionDisposalHazardListItem";

export class CollectionDisposalHazardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
    };
  }

  componentDidMount() {
    this.getItemsBeingDisposed(this.props.collections);
  }

  getItemsBeingDisposed(collections) {
    if (collections.length > 0) {
      const items = [];
      collections.forEach((collection) => {
        var collection_items = collection.collection_items.concat(
          collection.additional_collection_items
        );
        if (collection_items.length > 0) {
          collection_items.forEach((item) => {
            if (item.completed === true) {
              let ewc_code =
                item.ewc_code || (item.stock_item && item.stock_item.ewc_code);
              let haz_code =
                item.haz_code ||
                (item.stock_item && item.stock_item.hazard_code);
              let weight = item.weight;
              if (items.length > 0) {
                for (let x = 0; x < items.length; x++) {
                  if (
                    items[x].ewc_code === ewc_code &&
                    items[x].haz_code === haz_code
                  ) {
                    items[x].weight =
                      parseFloat(items[x].weight) + parseFloat(weight);
                    break;
                  }
                  if (x + 1 === items.length) {
                    items.push({
                      ewc_code: ewc_code,
                      haz_code: haz_code,
                      weight: weight,
                    });
                    break;
                  }
                }
              } else {
                items.push({
                  ewc_code: ewc_code,
                  haz_code: haz_code,
                  weight: weight,
                });
              }
            }
          });
        }
      });
      this.setState({
        disposalItems: items,
        is_loaded: true,
      });
    } else {
      this.setState({
        disposalItems: [],
        is_loaded: true,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.is_loaded && (
          <div className="disposal-item-list">
            {this.state.disposalItems.length > 0 && (
              <div>
                <div className="collection-waste-info collection-waste-info-header">
                  <div className="dsp-col dsp-ewc-code">EWC Code</div>
                  <div className="dsp-col dsp-haz-code">Hazard Code</div>
                  <div className="dsp-col dsp-wgt">Estimated Weight kg</div>
                  <div className="dsp-col dsp-acc">Accept</div>
                </div>
                {this.state.disposalItems.map((item, key) => {
                  return (
                    <CollectionDisposalHazardListItem key={key} item={item} />
                  );
                })}
              </div>
            )}
            {this.state.disposalItems.length === 0 && (
              <div className="collection-waste-info">
                <span className="dsp-col dsp-nothing">Nothing to dispose</span>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
