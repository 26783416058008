import React from "react";

import { CollectionDisposalNonHazListItem } from "./CollectionDisposalNonHazListItem";

export class CollectionDisposalNonHazList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
    };
  }

  componentDidMount() {
    this.getItemsBeingDisposed(this.props.collections);
  }

  getItemsBeingDisposed(collections) {
    if (collections.length > 0) {
      const items = [];
      collections.forEach((collection) => {
        if (collection.collection_items.length > 0) {
          collection.collection_items.forEach((item) => {
            if (item.completed === true) {
              if (!items.includes(item.ewc_code)) {
                items.push(item.ewc_code);
              }
            }
          });
        }
      });
      this.setState({
        disposalItems: items,
        is_loaded: true,
      });
    } else {
      this.setState({
        disposalItems: [],
        is_loaded: true,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.is_loaded && (
          <div className="disposal-item-list">
            {this.state.disposalItems.length > 0 && (
              <div>
                <div className="collection-waste-info collection-waste-info-header">
                  <div className="dsp-col dsp-ewc-code">EWC Code</div>
                  <div className="dsp-col dsp-acc">Accepted</div>
                </div>
                {this.state.disposalItems.map((item, key) => {
                  return (
                    <CollectionDisposalNonHazListItem
                      key={key}
                      itemCode={item}
                    />
                  );
                })}
              </div>
            )}
            {this.state.disposalItems.length === 0 && (
              <div className="collection-waste-info">
                <span className="dsp-col dsp-ewc-code">Nothing to dispose</span>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
