import React from "react";

export class CollectionDetailAddLineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div style={{ width: "100%", overflow: "hidden" }}>
        <button
          className="btn-default btn-add"
          onClick={() => this.props.onClick()}
        >
          <span className="plus"></span>
        </button>
      </div>
    );
  }
}
