import React from "react";
import { Redirect } from "react-router";

import { CollectionDisposalHazardTopActionBar } from "./CollectionDisposalHazardTopActionBar";
import { CollectionDisposalHazardBottomActionBar } from "./CollectionDisposalHazardBottomActionBar";
import { CollectionDisposalHazardAddressDetails } from "./CollectionDisposalHazardAddressDetails";
import { CollectionDisposalHazardSummary } from "./CollectionDisposalHazardSummary";
import { CollectionDisposalHazardLegal } from "./CollectionDisposalHazardLegal";

import CompletedCollectionsIndexedDB from "../../../../CompletedCollectionsIndexedDB";
import APIService from "../../../../APIService";

export class CollectionDisposalHazard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      disposalCollection: {
        permit_number: "",
        signature: "",
      },
    };
    if (!localStorage.getItem("driver")) {
      props.history.push({ pathname: "/driver" });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (CompletedCollectionsIndexedDB !== undefined) {
      this.CompletedCollectionsIndexedDB = CompletedCollectionsIndexedDB;
      this.CompletedCollectionsIndexedDB.createCompletedCollectionsIndexDB({
        objectStoreName: "collections",
        version: 3,
        onsuccess: this.completedCollectionSuccess.bind(this),
      });
    }
    if (this.props.location.signatureRedirect === true) {
      this.setState({
        disposalCollection: {
          signature: this.props.location.collection.signature || "",
          permit_number: this.props.location.collection.permit_number || "",
        },
      });
    }
  }

  completedCollectionSuccess(event) {
    if (event.target.result) {
      this.CompletedCollectionsIndexedDB.db = event.target.result;
      this.getCompletedCollections();
    }
  }

  getCompletedCollections() {
    CompletedCollectionsIndexedDB.getCollectionsInStore({
      onsuccess: this.getCompletedCollectionsSuccess.bind(this),
    });
  }

  getCompletedCollectionsSuccess(event) {
    const completedCollections = event.target.result;
    if (completedCollections) {
      const disposalType = this.determineDisposalType(completedCollections);
      this.setState({
        completedCollections: completedCollections,
        is_loaded: true,
        disposalType: disposalType,
      });
    }
  }

  clearCompletedCollections() {
    var completed_collections = [this.state.disposalCollection];
    if (this.state.completedCollections.length > 0) {
      completed_collections = completed_collections.concat(
        this.state.completedCollections
      );
    }
    APIService.postDisposal(completed_collections);
    CompletedCollectionsIndexedDB.clearCollectionsInStore({
      onsuccess: this.clearCompletedCollectionsSuccess.bind(this),
    });
  }

  clearCompletedCollectionsSuccess(event) {
    this.setState({
      redirect: "/",
    });
  }

  determineDisposalType(collections) {
    return "hazardous";
  }

  permitChange(event) {
    this.setState({
      disposalCollection: {
        ...this.state.disposalCollection,
        permit_number: event.target.value,
      },
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <CollectionDisposalHazardTopActionBar displayButtonLeft={true} />
        {this.state.is_loaded === true && (
          <div id="disposal-details" className="page-content disposal-haz">
            <CollectionDisposalHazardAddressDetails
              disposalType={this.state.disposalType}
            />
            <hr></hr>
            <CollectionDisposalHazardSummary
              disposalType={this.state.disposalType}
              collections={this.state.completedCollections}
            />
            <CollectionDisposalHazardLegal
              disposalType={this.state.disposalType}
              disposalCollection={this.state.disposalCollection}
              handlePermitChange={this.permitChange.bind(this)}
              headerText="Cosignor Signature"
            />
          </div>
        )}
        <CollectionDisposalHazardBottomActionBar
          labelButtonRight="Confirm"
          displayButtonRight={true}
          onClickButtonRight={this.clearCompletedCollections.bind(this)}
        />
      </>
    );
  }
}
