import React from "react";

import APIService from "../../../../APIService";

import { DriverTopActionBar } from "./DriverTopActionBar";
import { DriverBottomActionBar } from "./DriverBottomActionBar";
import { DriverListItem } from "./DriverListItem";

export class Driver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drivers: {},
    };
  }

  componentDidMount() {
    this.getDrivers();
  }

  getDrivers() {
    APIService.getDrivers().then((data) =>
      this.setState({ drivers: data.data })
    );
  }

  render() {
    let drivers = this.state.drivers;

    return (
      <>
        <DriverTopActionBar
          labelButtonRight=""
          displayButtonLeft={false}
          headerText="Select Driver"
        />
        <div id="driver-list" className="page-content">
          {drivers.length > 0 &&
            drivers.map((driver, index) => {
              return (
                <DriverListItem redirect={false} key={index} {...driver} />
              );
            })}
        </div>
        <DriverBottomActionBar
          labelButtonRight=""
          displayButtonRight={false}
          displayButtonLeft={true}
          labelButtonLeft=""
          hideButtonLeft={true}
        />
      </>
    );
  }
}
