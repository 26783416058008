import React from "react";
import { TopActionBar } from "../../ActionBars/TopActionBar";

export class CollectionListTopActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_day: this.props.collectionDay || 7,
      display_next_day_button: true,
      display_previous_day_button: false,
    };
  }

  incrementDay() {
    const active_day = this.props.collectionDay;
    const next_day = active_day + 1;
    if (next_day < this.props.days.length) {
      this.setState({
        active_day: next_day,
      });
      this.props.setCurrentCollectionDay(next_day);
      if (next_day + 1 === this.props.days.length) {
        this.setState({
          display_next_day_button: false,
        });
      }
      if (next_day > 0) {
        this.setState({
          display_previous_day_button: true,
        });
      }
    }
  }

  deincrementDay() {
    const active_day = this.props.collectionDay;
    const next_day = active_day - 1;
    if (next_day >= 0) {
      this.setState({
        active_day: next_day,
      });
      this.props.setCurrentCollectionDay(next_day);
      if (next_day - 1 < 0) {
        this.setState({
          display_previous_day_button: false,
        });
      }
      if (next_day < this.props.days.length) {
        this.setState({
          display_next_day_button: true,
        });
      }
    }
  }

  render() {
    const active_day = this.props.collectionDay || this.state.active_day;
    var active_day_text = "";
    if (this.props.days.length > 0) {
      var display_next_day_button =
        this.props.collectionDay + 1 < this.props.days.length ? true : false;
      var display_previous_day_button =
        this.props.collectionDay > 0 ? true : false;
      active_day_text = `${
        this.props.days[this.props.collectionDay]
      } Collections`;
    }
    return (
      <TopActionBar
        headerText={active_day_text}
        displayButtonRight={display_next_day_button}
        displayButtonLeft={display_previous_day_button}
        labelButtonRight={null}
        labelButtonLeft={null}
        onClickButtonRight={this.incrementDay.bind(this)}
        onClickButtonLeft={this.deincrementDay.bind(this)}
      />
    );
  }
}
