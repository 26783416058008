import React from "react";
import { Redirect } from "react-router-dom";

export class DriverListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  updateDriver(id, firstname, lastname) {
    var driver = JSON.stringify({ id: id, name: firstname + " " + lastname });
    localStorage.setItem("driver", driver);
    this.setState({ redirect: true });
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  render() {
    return (
      <button
        className="btn-default driver-row"
        onClick={() =>
          this.updateDriver(
            this.state.unique_id,
            this.state.firstname,
            this.state.lastname
          )
        }
      >
        {this.renderRedirect()}
        {this.state.firstname} {this.state.lastname}
      </button>
    );
  }
}
