import React from "react";

export class CollectionDisposalNonHazListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
    };
  }

  render() {
    return (
      <div className="collection-waste-info">
        <div className="dsp-col dsp-ewc-code">{this.props.itemCode}</div>
        <div className="dsp-col dsp-acc">
          <input type="checkbox" name=""></input>
        </div>
      </div>
    );
  }
}
