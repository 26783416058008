import React from "react";

import { Select } from "../../Inputs/Select";

export class CollectionDetailListItemSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  handleChange(event) {
    const value = event.target.value;
    this.props.handleInputChange(this.props.propertyName, value);
  }

  render() {
    return (
      <Select
        className={this.props.classes}
        defaultValue={this.props.defaultValue}
        disable={this.props.disable}
        optionList={this.props.optionList}
        optionListMapping={this.props.optionListMapping}
        handleChange={this.handleChange.bind(this)}
      />
    );
  }
}
