import React from "react";

import CompletedCollectionsIndexedDB from "../../../../CompletedCollectionsIndexedDB";

export class Debug extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    if (!localStorage.getItem("driver")) {
      props.history.push({ pathname: "/driver" });
    }
  }

  addCollectionToCompleted() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      CompletedCollectionsIndexedDB.addCollectionToStore({
        data: { id: 1, title: "test", unique_id: "as32uyr87923rgduhf" },
        onsuccess: this.transactionSuccess,
      });
      CompletedCollectionsIndexedDB.addCollectionToStore({
        data: { id: 2, title: "test 2", unique_id: "gf2ugf78923gf7283f" },
        onsuccess: this.transactionSuccess,
      });
      CompletedCollectionsIndexedDB.addCollectionToStore({
        data: { id: 3, title: "test 3", unique_id: "2j3f9023j0fnfz9dsf" },
        onsuccess: this.transactionSuccess,
      });
    }
  }

  deleteCollectionToCompleted() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      CompletedCollectionsIndexedDB.deleteCollectionInStore({
        key: "gf2ugf78923gf7283f",
        onsuccess: this.transactionSuccess,
      });
    }
  }

  putCollectionToCompleted() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      CompletedCollectionsIndexedDB.updateCollectionInStore({
        data: { id: 4, title: "test 4", unique_id: "as32uyr87923rgduhf" },
        onsuccess: this.transactionSuccess,
      });
    }
  }

  clearCollectionToCompleted() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      CompletedCollectionsIndexedDB.clearCollectionsInStore({
        onsuccess: this.transactionSuccess,
      });
    }
  }

  get1CollectionToCompleted() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      CompletedCollectionsIndexedDB.getCollectionInStore({
        key: "2j3f9023j0fnfz9dsf",
        onsuccess: this.transactionSuccess,
      });
    }
  }

  getAllCollectionToCompleted() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      CompletedCollectionsIndexedDB.getCollectionsInStore({
        onsuccess: this.transactionSuccess,
      });
    }
  }

  transactionSuccess(event) {
    console.log(event.target.result);
  }

  render() {
    return (
      <div className="collection-detail page-outer">
        <button onClick={this.addCollectionToCompleted.bind(this)}>Add</button>
        <button onClick={this.deleteCollectionToCompleted.bind(this)}>
          Delete
        </button>
        <button onClick={this.putCollectionToCompleted.bind(this)}>Put</button>
        <button onClick={this.clearCollectionToCompleted.bind(this)}>
          Clear
        </button>
        <button onClick={this.get1CollectionToCompleted.bind(this)}>
          Get 1
        </button>
        <button onClick={this.getAllCollectionToCompleted.bind(this)}>
          Get all
        </button>
      </div>
    );
  }
}
