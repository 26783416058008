import React from "react";

import { DriverIcon } from "../Icons/DriverIcon";

export class ButtonLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    var btnClasses = "btn-default nav-btn-square ml-15";
    if (this.props.typeButton == "driver") {
      btnClasses += " driver-switch-btn";
    }
    if (!this.props.displayButton) {
      btnClasses += " no-vis";
    }
    if (this.props.hideButton) {
      btnClasses += " no-display";
    }
    return (
      <button className={btnClasses} onClick={() => this.props.onClick()}>
        {this.props.typeButton == "driver" ? (
          <DriverIcon />
        ) : (
          <span className="chevron chevron-left"></span>
        )}
        {this.props.label ? (
          <span className="btn-text btn-text-right">{this.props.label}</span>
        ) : (
          ""
        )}
      </button>
    );
  }
}
