import React from "react";

import { Sign } from "../../Inputs/Sign";
import { CollectionDetailComment } from "./CollectionDetailComment";

export class CollectionDetailLegal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  setCollectionInfo(name, value) {
    var collection = this.props.collection;
    collection[name] = value;
    this.props.updateCollection(collection);
  }

  render() {
    var signature = this.props.collection.signature;
    return (
      <>
        <div id="confirm-details-section">
          <div className="customer-sign">
            <div className="div-half">
              <h3>Carrier Certificate</h3>
              <p>
                Clinical Waste Solutions Ltd (CWS LTD), Unit 17, The Glenmore
                Centre, Fancy Road, Poole, BH12 4FB.<br></br>Registration Number
                CB/GE5809NN.
              </p>
              <h3>Cosignor certificate</h3>
              <p>
                I certify that the information above is correct and that the
                carrier is registered or excempt and was advised of the
                appropriate precautionary measures. All of the waste is packaged
                and labeled correctly and the carrier has been advised of any
                special handling requrements.<br></br>
                <br></br>I confirm that I have fulfilled my duty to apply the
                waste hierarchy as required by regulations 12 of the waste
                (England and Wales) Regulations 2011.
              </p>
            </div>
            <div className="div-half text-center">
              <Sign
                collection={this.props.collection}
                headerText={this.props.headerText}
                imageUrl={
                  signature !== undefined && signature !== ""
                    ? signature
                    : false
                }
                imageText="Cosignor Signature"
                imageLink={
                  "/detail/signature/" + this.props.collection.unique_id
                }
                imageHeight={350}
                imageWidth={350}
                label="Save"
              />
            </div>
          </div>
          <CollectionDetailComment
            handleInputChange={this.setCollectionInfo.bind(this)}
            collection={this.props.collection}
          />
        </div>
      </>
    );
  }
}
