import React from "react";

export class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <input
        type={this.props.type}
        className={this.props.className}
        value={this.props.value}
        placeholder={this.props.placeholder}
        disabled={this.props.disable}
        onChange={(event) => this.props.handleChange(event)}
      />
    );
  }
}
