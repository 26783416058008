import React from "react";

export class CollectionDisposalLegal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div id="confirm-details-section">
        <div className="customer-sign">
          <div className="div-half">
            <h3>Carrier Certificate</h3>
            <p className="no-margin">
              Clinical Waste Solutions Ltd (CWS LTD), Unit 17, The Glenmore
              Centre, Fancy Road, Poole, BH12 4FB.<br></br>Registration Number
              CB/GE5809NN.
            </p>
            <h3>Cosignor certificate</h3>
            <p className="no-margin">
              I have received the accepted waste as detailed above at the above
              disposal address on the above date.<br></br>
              <br></br>I confirm that I have fulfilled my duty to apply the
              waste hierarchy as required by regulations 12 of the waste
              (England and Wales) Regulations 2011.
            </p>
            {this.props.disposalType === "non_hazardous" && (
              <div className="disposal-extra">
                <p>
                  <strong>Nett weight(kg)</strong>
                </p>
                <input type="number" name="" className="input-full"></input>
              </div>
            )}
            {this.props.disposalType === "hazardous" && (
              <div className="disposal-extra">
                <p>
                  <strong>Permit Number</strong>
                </p>
                <input type="text" name="" className="input-full"></input>
              </div>
            )}
          </div>
          <div className="div-half">
            <div className="sign-area-overlay">Tap here to sign</div>
            <canvas id="customer-sign-area"></canvas>
          </div>
        </div>
      </div>
    );
  }
}
