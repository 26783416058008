import React from "react";
import { Redirect } from "react-router";

import { CollectionDisposalTopActionBar } from "./CollectionDisposalTopActionBar";
import { CollectionDisposalBottomActionBar } from "./CollectionDisposalBottomActionBar";
import { CollectionDisposalAddressDetails } from "./CollectionDisposalAddressDetails";
import { CollectionDisposalSummary } from "./CollectionDisposalSummary";
import { CollectionDisposalLegal } from "./CollectionDisposalLegal";

import CompletedCollectionsIndexedDB from "../../../../CompletedCollectionsIndexedDB";

export class CollectionDisposal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
    };
    if (!localStorage.getItem("driver")) {
      props.history.push({ pathname: "/driver" });
    }
  }

  componentDidMount() {
    if (CompletedCollectionsIndexedDB !== undefined) {
      this.CompletedCollectionsIndexedDB = CompletedCollectionsIndexedDB;
      this.CompletedCollectionsIndexedDB.createCompletedCollectionsIndexDB({
        objectStoreName: "collections",
        version: 3,
        onsuccess: this.completedCollectionSuccess.bind(this),
      });
    }
  }

  completedCollectionSuccess(event) {
    if (event.target.result) {
      this.CompletedCollectionsIndexedDB.db = event.target.result;
      this.getCompletedCollections();
    }
  }

  getCompletedCollections() {
    CompletedCollectionsIndexedDB.getCollectionsInStore({
      onsuccess: this.getCompletedCollectionsSuccess.bind(this),
    });
  }

  getCompletedCollectionsSuccess(event) {
    const completedCollections = event.target.result;
    if (completedCollections) {
      const disposalType = this.determineDisposalType(completedCollections);
      this.setState({
        completedCollections: completedCollections,
        is_loaded: true,
        disposalType: disposalType,
      });
    }
  }

  clearCompletedCollections() {
    CompletedCollectionsIndexedDB.clearCollectionsInStore({
      onsuccess: this.clearCompletedCollectionsSuccess.bind(this),
    });
  }

  clearCompletedCollectionsSuccess(event) {
    this.setState({
      redirect: "/",
    });
  }

  determineDisposalType(collections) {
    // work out the disposal type
    return "non_hazardous";
    //return 'hazardous';
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <CollectionDisposalTopActionBar displayButtonLeft={true} />
        {/* We need to decide which disposal screen to show, non haz or haz */}
        {this.state.is_loaded === true && (
          <div id="disposal-details" className="page-content disposal-non-haz">
            <CollectionDisposalAddressDetails
              disposalType={this.state.disposalType}
            />
            <hr></hr>
            <CollectionDisposalSummary
              disposalType={this.state.disposalType}
              collections={this.state.completedCollections}
            />
            <CollectionDisposalLegal disposalType={this.state.disposalType} />
          </div>
        )}
        <CollectionDisposalBottomActionBar
          labelButtonRight="Confirm"
          displayButtonRight={true}
          onClickButtonRight={this.clearCompletedCollections.bind(this)}
        />
      </>
    );
  }
}
