import React from "react";
import { Refresh } from "../../Icons/Refresh";

export class CollectionListRefresh extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <div className="collection-row collection-row-options">
        <button
          className="btn-default btn-light"
          onClick={() => this.props.onClick()}
        >
          <Refresh />
        </button>
        <p className="text-ellipsis">{this.props.labelText}</p>
      </div>
    );
  }
}
