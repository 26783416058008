import React from "react";

export class CollectionDisposalHazardListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
    };
  }

  render() {
    return (
      <div className="collection-waste-info">
        <div className="dsp-col dsp-ewc-code">{this.props.item.ewc_code}</div>
        <div className="dsp-col dsp-haz-code">{this.props.item.haz_code}</div>
        <div className="dsp-col dsp-wgt">
          {parseFloat(this.props.item.weight).toFixed(2)}
        </div>
        <div className="dsp-col dsp-acc">
          <input className="dsp-acc-check" type="checkbox" name=""></input>
        </div>
      </div>
    );
  }
}
