import React from "react";
//import './Collection.css';
import "./stylesheet.css";
import { DriverApp } from "./DriverApp";

class App extends React.Component {
  render() {
    return <DriverApp />;
  }
}

export default App;
