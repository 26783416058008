import React from "react";

import { CollectionDetailListItemSelect } from "./CollectionDetailListItemSelect";
import { CollectionDetailListItemInput } from "./CollectionDetailListItemInput";
import { CollectionDetailListItemCheck } from "./CollectionDetailListItemCheck";

export class CollectionDetailAddOnListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  componentDidMount() {
    if (
      !this.props.header &&
      !this.props.item.collected &&
      !this.props.item.delivered
    ) {
      this.setCollectionItemDefault();
    }
  }

  setCollectionItemProperty(property, value) {
    var item = this.props.item;
    if (property === "stock_item") {
      var stockItemIndex = this.props.stock.findIndex(
        (i) => i.unique_id === value
      );
      if (stockItemIndex >= 0) {
        if (
          item.stock_item.unique_id !==
          this.props.stock[stockItemIndex].unique_id
        ) {
          item.weight = this.props.stock[stockItemIndex].weight_kg;
          item.collected = 0;
          item.delivered = 0;
          //this.setCollectionItemDefault();
        }
        item.stock_item = this.props.stock[stockItemIndex];
      }
    } else {
      item[property] = value;
      if ((property === "collected" || property === "delivered") && value > 0) {
        item.completed = true;
      }
    }
    this.props.updateCollectionItem(item);
  }

  setCollectionItemDefault() {
    this.setCollectionItemProperty("collected", "");
    this.setCollectionItemProperty("delivered", "");
  }

  render() {
    return (
      <div
        className="collection-waste-info test"
        id={this.props.item.unique_id}
      >
        <div className="collection-waste-info-label">Stock Item:</div>
        <div className="collection-waste-info-input cwi-stock">
          <CollectionDetailListItemSelect
            classes=""
            optionList={this.props.stock}
            optionListMapping={{ value: "unique_id", text: "name" }}
            defaultValue={this.props.item.stock_item.unique_id}
            disable={false}
            handleInputChange={this.setCollectionItemProperty.bind(this)}
            propertyName="stock_item"
          />
        </div>
        <div className="collection-waste-info-label">Collected:</div>
        <div className="collection-waste-info-input cwi-collected">
          <CollectionDetailListItemInput
            classes=""
            defaultValue={this.props.item.collected || ""}
            placeholder={"0"}
            disable={false}
            handleInputChange={this.setCollectionItemProperty.bind(this)}
            propertyName="collected"
          />
        </div>
        <div className="collection-waste-info-label">EWC Code:</div>
        <div className="collection-waste-info-text">
          {this.props.item.stock_item.ewc_code
            ? this.props.item.stock_item.ewc_code
            : "-"}
        </div>
        <div className="collection-waste-info-label">Delivered:</div>
        <div className="collection-waste-info-input cwi-delivered">
          <CollectionDetailListItemInput
            classes=""
            defaultValue={this.props.item.delivered || ""}
            placeholder={"0"}
            disable={false}
            handleInputChange={this.setCollectionItemProperty.bind(this)}
            propertyName="delivered"
          />
        </div>
        <div className="collection-waste-info-label">Haz Code:</div>
        <div className="collection-waste-info-text">
          {this.props.item.stock_item.haz_code
            ? this.props.item.stock_item.haz_code
            : "-"}
        </div>
        <div className="collection-waste-info-label">Weight(kg):</div>
        <div className="collection-waste-info-input cwi-weight">
          <CollectionDetailListItemInput
            classes=""
            defaultValue={
              this.props.item.weight && this.props.item.weight != "0.00"
                ? this.props.item.weight
                : ""
            }
            placeholder={"0.00"}
            disable={false}
            handleInputChange={this.setCollectionItemProperty.bind(this)}
            propertyName="weight"
          />
        </div>
        <div
          className="btn-default waste-delete-btn"
          style={{ display: this.props.item.confirmed === false ? "block" : "none" }}
          onClick={() => {
            if (window.confirm("Delete this item?")) {
              this.props.removeCollectionItem(this.props.item.unique_id)
            }
          }}
        >
          Delete
        </div>
      </div>
    );
  }
}
