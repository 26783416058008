import React, { Fragment } from "react";
import { Routes } from "./Routes";

export class DriverApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <Fragment>
        <Routes />
      </Fragment>
    );
  }
}
