import React from "react";
import { Link } from "react-router-dom";

export class Sign extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };
  }

  componentDidMount() {
    if (this.props.imageUrl === false) {
      var canvas = this.refs.canvas;
      var context = canvas.getContext("2d");
      canvas.width = this.props.imageHeight;
      canvas.height = this.props.imageHeight;
      context.lineWidth = 5;

      context.font = "2em sans-serif";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = "#ddd";
      context.fillText("Tap here to sign", canvas.width / 2, canvas.height / 2);
    }
  }

  render() {
    return (
      <Link
        to={{
          pathname: this.props.imageLink,
          collection: this.props.collection,
          headerText: this.props.headerText,
          labelButtonLeft: null,
          displayButtonLeft: true,
          labelButtonRight: this.props.label,
          displayButtonRight: true,
        }}
      >
        {this.props.imageUrl !== false ? (
          <img
            id="cosignor-signature-img"
            src={"data:image/png;base64," + this.props.imageUrl}
            alt="Cosignor Signature"
            height={this.props.imageHeight}
            width={this.props.imageWidth}
          />
        ) : (
          <canvas ref="canvas" id="customer-sign-area"></canvas>
        )}
      </Link>
    );
  }
}
