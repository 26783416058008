import React from "react";
import { Link } from "react-router-dom";

export class CollectionListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    var isComplete = false;
    if (this.props.completedCollections) {
      if (this.props.completedCollections.includes(this.props.unique_id)) {
        isComplete = true;
      }
    }
    if (this.props.status && isComplete === false) {
      if (this.props.status === "completed") {
        isComplete = true;
      }
    }
    
    return (
      <Link
        to={{
          pathname: "/detail/" + this.props.unique_id,
          collection: { ...this.props },
          collectionDay: this.props.collectionDay,
        }}
      >
        <div
          className={
            isComplete === true
              ? "collection-row collection-complete"
              : "collection-row"
          }
        >
          <p className="col-location text-ellipsis">
            {this.props.customer.name}
          </p>
          <p className="col-postcode text-ellipsis">
            {this.props.customer.address.postcode}
          </p>
          <p className="col-icon">
            <span
              className={
                isComplete === true ? "chevron tick" : "chevron chevron-right"
              }
            ></span>
          </p>
        </div>
      </Link>
    );
  }
}
